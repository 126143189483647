import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageLoading from './user/loading_components/PageLoading';
import './user/css/user_style.css';
import axios from 'axios';
import Protected from './admin/Protected';





// For  authontication

//axios.defaults.baseURL = "http://localhost:5000/";
//axios.defaults.baseURL = "http://3.215.163.228:5000/";
axios.defaults.baseURL = "https://o4b3nx8227.execute-api.us-east-1.amazonaws.com/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;


function App() {
  const Index = lazy(() => import('./user/Index'));
  const ProgramJJM = lazy(() => import('./user/ProgramJJM'));
  const ProgramSBM = lazy(() => import('./user/ProgramSBM'));
  const ProgramSOPD = lazy(() => import('./user/ProgramSOPD'));
  const Grievance = lazy(() => import('./user/Grievance'));
  const Services_FHTC = lazy(() => import('./user/Services_FHTC'));
  const Services_status_of_JJM = lazy(() => import('./user/Services_status_of_JJM'));
  const ServicesCoverageStatus = lazy(() => import('./user/ServicesCoverageStatus'));
  const ServicesInformationJJM = lazy(() => import('./user/ServicesInformationJJM'));
  const ServicesWaterSanitization = lazy(() => import('./user/ServicesWaterSanitization'));
  const ServicesModelProjects = lazy(() => import('./user/ServicesModelProjects'));
  const OrganisationalStructure = lazy(() => import('./user/OrganisationalStructure'));
  const Organisationdivision = lazy(() => import('./user/Organisationdivision'));
  const OrganisationSubDivision = lazy(() => import('./user/OrganisationSubDivision'));
  const OrganisationLeb = lazy(() => import('./user/OrganisationLeb'));
  const Notification = lazy(() => import('./user/Notification'));
  const Tender = lazy(() => import('./user/Tender'));
  const ImageGellary = lazy(() => import('./user/ImageGellary'));
  const Login = lazy(() => import('./user/Login'));
  const Error = lazy(() => import('./user/Error'));


  /*  Protected route for admin  */

  const Dashboard = lazy(() => import('./admin/Dashboard'));
  const Logout = lazy(() => import('./admin/Logout'));
  const GrievanceDetails = lazy(() => import('./admin/GrievanceDetails'));

  return (
    <div className="App" >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <Suspense fallback={<PageLoading />}>
              <Index />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/programJJM" element={
            <Suspense fallback={<PageLoading />}>
              <ProgramJJM />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/programSVM" element={
            <Suspense fallback={<PageLoading />}>
              <ProgramSBM />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/ProgramSOPD" element={
            <Suspense fallback={<PageLoading />}>
              <ProgramSOPD />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/grievance" element={
            <Suspense fallback={<PageLoading />}>
              <Grievance />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/services_FHTC" element={
            <Suspense fallback={<PageLoading />}>
              <Services_FHTC />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/services_status_of_JJM" element={
            <Suspense fallback={<PageLoading />}>
              <Services_status_of_JJM />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/services_coverage_status" element={
            <Suspense fallback={<PageLoading />}>
              <ServicesCoverageStatus />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/notification" element={
            <Suspense fallback={<PageLoading />}>
              <Notification />
            </Suspense>
          } />
        </Routes>


        <Routes>
          <Route path="/PHED/services_informationJJM" element={
            <Suspense fallback={<PageLoading />}>
              <ServicesInformationJJM />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/services_water_sanitization" element={
            <Suspense fallback={<PageLoading />}>
              <ServicesWaterSanitization />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/Services_model_projects" element={
            <Suspense fallback={<PageLoading />}>
              <ServicesModelProjects />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/organisational_structure" element={
            <Suspense fallback={<PageLoading />}>
              <OrganisationalStructure />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/organisation_division" element={
            <Suspense fallback={<PageLoading />}>
              <Organisationdivision />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/organisation_subdivision" element={
            <Suspense fallback={<PageLoading />}>
              <OrganisationSubDivision />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/organisation_leb" element={
            <Suspense fallback={<PageLoading />}>
              <OrganisationLeb />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/tender" element={
            <Suspense fallback={<PageLoading />}>
              <Tender />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/image_gellary" element={
            <Suspense fallback={<PageLoading />}>
              <ImageGellary />
            </Suspense>
          } />
        </Routes>


        <Routes>
          <Route path="/PHED/error" element={
            <Suspense fallback={<PageLoading />}>
              <Error />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/PHED/login" element={
            <Suspense fallback={<PageLoading />}>
              <Login />
            </Suspense>
          } />
        </Routes>

        {/* Protecetd route for Admin */}

        <Routes>
          <Route path="/PHED/logout" element={
            <Suspense fallback={<PageLoading />}>
              <Logout />
            </Suspense>
          } />
        </Routes>
        <Routes>
          <Route path="/admin/dashboard" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={Dashboard} />
            </Suspense>
          } />
        </Routes>

        <Routes>
          <Route path="/admin/grievanceDetails" element={
            <Suspense fallback={<PageLoading />}>
              <Protected Cmp={GrievanceDetails} />
            </Suspense>
          } />
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
